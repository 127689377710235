import * as React from 'react'
import { graphql, Link } from 'gatsby'
import { render, MARK_LINK } from 'storyblok-rich-text-react-renderer'

import Layout from '../components/Layout'
import Seo from '../components/Seo'
import useStoryblok from '../utils/storyblok'

const IndexPage = ({ data, location }) => {
  const story = useStoryblok(data.story, location)
  const { content } = story

  const settings = content.body.find(
    (blok) => blok.component === 'HomepageSettings',
  )
  const bgImageStyle =
    settings.background_type === 'gradient'
      ? {
          background: `linear-gradient(180deg, ${settings.gradient_from}, ${settings.gradient_to})`,
        }
      : {
          backgroundPosition: `50% ${50 - Number(settings.top_offset)}%`,
          backgroundImage: `url("${settings.image.filename}")`,
        }
  const colorStyle = {
    color: `${settings.title_color}`,
  }

  return (
    <Layout isNotPadded>
      <Seo title="Home" />
      <div className="title__top">
        <h1 className="title">{content.title}</h1>
      </div>
      <div className="title__bottom" style={bgImageStyle}>
        <h2 className="title" style={colorStyle}>
          {content.title}
        </h2>
        <div className="title__bottom__text" style={colorStyle}>
          {render(settings.text, {
            markResolvers: {
              [MARK_LINK]: (children, { href, target }) =>
                target === '_self' ? (
                  <Link style={colorStyle} to={href}>
                    {children}
                  </Link>
                ) : (
                  <a style={colorStyle} href={href} target={target}>
                    {children}
                  </a>
                ),
            },
          })}
        </div>
      </div>
    </Layout>
  )
}

export default IndexPage

export const query = graphql`
  {
    story: storyblokEntry(full_slug: { eq: "home" }) {
      name
      content
      full_slug
      uuid
    }
  }
`
